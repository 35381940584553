import { createReducer, on } from '@ngrx/store'
import { ReservationState, reservationInitialState, resetState } from './reservation.state'
import { Section } from './reservation'
import { ReservationActions } from './reservation.actions'

export const reservationReducer = createReducer<ReservationState>(
	reservationInitialState,
	on(ReservationActions.setOrderIdAfterLoadingAvailabilities, (state, action): ReservationState => {
		return {
			...state,
			orderId: action.orderId,
		}
	}),
	on(ReservationActions.setSelectedDate, (state, action): ReservationState => {
		const sections = state.availabilities?.find(av => av.reservationDay === action.selectedDate)?.sections
		return {
			...state,
			...resetState,
			selectedDate: action.selectedDate,
			sections: sections?.filter(section => section.available),
		}
	}),
	on(ReservationActions.setSelectedSection, (state, action): ReservationState => {
		const section = state.sections?.find(sc => sc.label === action.selectedSectionLabel) as Section
		return {
			...state,
			selectedPartySize: String(section.schedules[0].partySize),
			selectedReservationTime: null,
			reservationTimes: null,
			selectedSectionLabel: action.selectedSectionLabel,
			schedules: section?.schedules.filter(schedule => schedule.available),
			selectedSectionId: section?.id,
		}
	}),
	on(ReservationActions.setScheduleFromPartysize, (state, action): ReservationState => {
		const reservationTimes = state.schedules?.find(
			schedule => `${schedule.partySize}` === action.selectedPartySize
		)?.reservationTimes
		return {
			...state,
			selectedPartySize: action.selectedPartySize,
			selectedReservationTime: null,
			reservationTimes: reservationTimes?.filter(time => time.available),
		}
	}),
	on(ReservationActions.setReservationTime, (state, action): ReservationState => {
		return {
			...state,
			selectedReservationTime: action.selectedReservationTime,
		}
	}),
	on(ReservationActions.loadAvailabilities, (state): ReservationState => {
		return {
			...state,
			statusAvailabilities: 'pending',
			errorAvailability: null,
		}
	}),
	on(ReservationActions.loadAvailabilitiesSuccess, (state, action): ReservationState => {
		return {
			...state,
			errorAvailability: null,
			statusAvailabilities: 'success',
			availabilities: action.availabilities.filter(availability => availability.available),
		}
	}),
	on(ReservationActions.loadAvailabilitiesFail, (state, action): ReservationState => {
		return {
			...state,
			errorAvailability: action.error,
			statusAvailabilities: 'failure',
			availabilities: [],
		}
	}),
	on(ReservationActions.confirmReservation, (state): ReservationState => {
		return {
			...state,
			statusConfirmReservation: 'pending',
			errorConfirmation: null,
		}
	}),
	on(ReservationActions.confirmReservationSuccess, (state): ReservationState => {
		return {
			...state,
			statusConfirmReservation: 'success',
			errorConfirmation: null,
		}
	}),
	on(ReservationActions.confirmReservationFail, (state, action): ReservationState => {
		return {
			...state,
			errorConfirmation: action.error,
			statusConfirmReservation: 'failure',
		}
	}),
	on(ReservationActions.resetReservationState, (): ReservationState => {
		return {
			...reservationInitialState,
		}
	}),
	on(ReservationActions.resetInitialStateSuccess, (state): ReservationState => {
		return {
			...state,
			statusConfirmReservation: 'idle',
		}
	})
)
