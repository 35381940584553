import { Route } from '@angular/router'
import { authGuard } from '@monorepo-channels/channels/domain'

const pages = {
	LoginSSOComponent: () => import('./pages/loginSSO/login-sso.page').then(x => x.LoginSSOPageComponent),
	CardDetailsComponent: () =>
		import('./pages/wallet/card-details/card-details.component').then(x => x.CardDetailsComponent),
	CheckinSuccessComponent: () =>
		import('./pages/checkin/checkin-success/checkin-success.component').then(
			x => x.CheckinSuccessComponent
		),
	CheckinPageComponent: () => import('./pages/checkin/checkin.page').then(x => x.CheckinPageComponent),
	ContactsComponent: () =>
		import('./pages/menu/contacts/contacts.component').then(x => x.ContactsComponent),
	FaqComponent: () => import('./pages/menu/faq/faq.component').then(x => x.FaqComponent),
	HowItWorkComponent: () =>
		import('./pages/menu/how-it-work/how-it-work.page').then(x => x.HowItWorkComponent),
	NewCardComponent: () =>
		import('./pages/wallet/new-card/new-card.component').then(x => x.NewCardComponent),
	OrderDetailsPageComponent: () =>
		import('./pages/orders-history/order-details/order-details.page').then(
			x => x.OrderDetailsPageComponent
		),
	OrdersHistoryPageComponent: () =>
		import('./pages/orders-history/orders-history.page').then(x => x.OrdersHistoryPageComponent),
	PaymentSuccessComponent: () =>
		import('./pages/payment/payment-success/payment-success.component').then(
			x => x.PaymentSuccessComponent
		),
	PaymentPageComponent: () => import('./pages/payment/payment.page').then(x => x.PaymentPageComponent),
	ReservationSuccessComponent: () =>
		import('./pages/reservation/reservation-success/reservation-success.page').then(
			x => x.ReservationSuccessComponent
		),
	ReservationPageComponent: () =>
		import('./pages/reservation/reservation.page').then(x => x.ReservationPageComponent),
	TermsOfUseComponent: () =>
		import('./pages/menu/terms-of-use/terms-of-use.component').then(x => x.TermsOfUseComponent),
	VenueDetailsPageComponent: () =>
		import('./pages/venues-list/venue-details/venue-details.page').then(x => x.VenueDetailsPageComponent),
	VenueListPageComponent: () =>
		import('./pages/venues-list/venues-list.page').then(x => x.VenueListPageComponent),
	WalletComponent: () => import('./pages/wallet/wallet.component').then(x => x.WalletComponent),
	NoSignal: () => import('./pages/no-signal/no-signal.component').then(x => x.NoSignalPageComponent),
}

export const APP_ROUTES: Route[] = [
	{
		path: 'login',
		loadComponent: pages.LoginSSOComponent,
		title: 'Unico Gastronomia - Login',
	},
	{
		path: '',
		loadComponent: pages.VenueListPageComponent,
		title: 'Unico Gastronomia',
	},
	{
		path: 'restaurante/:venueId',
		loadComponent: pages.VenueDetailsPageComponent,
		title: 'Unico Gastronomia - Restaurante',
		canActivate: [authGuard],
	},
	{
		path: 'restaurante/:venueId/reserva',
		loadComponent: pages.ReservationPageComponent,
		title: 'Unico Gastronomia - Restaurante - Reserva',
		canActivate: [authGuard],
	},
	{
		path: 'restaurante/:venueId/reserva/sucesso',
		loadComponent: pages.ReservationSuccessComponent,
		title: 'Unico Gastronomia - Restaurante - Reserva - Sucesso',
		canActivate: [authGuard],
	},
	{
		path: 'restaurante/:venueId/checkin',
		loadComponent: pages.CheckinPageComponent,
		title: 'Unico Gastronomia - Restaurante - Checkin',
		canActivate: [authGuard],
	},
	{
		path: 'restaurante/:venueId/checkin/sucesso',
		loadComponent: pages.CheckinSuccessComponent,
		title: 'Unico Gastronomia - Restaurante - Checkin - Sucesso',
		canActivate: [authGuard],
	},
	{
		path: 'carteira',
		loadComponent: pages.WalletComponent,
		title: 'Unico Gastronomia - Carteira',
		canActivate: [authGuard],
	},
	{
		path: 'carteira/cartao/:lastFourDigits',
		loadComponent: pages.CardDetailsComponent,
		title: 'Unico Gastronomia - Cartão',
		canActivate: [authGuard],
	},
	{
		path: 'carteira/cadastrar-cartao',
		loadComponent: pages.NewCardComponent,
		title: 'Unico Gastronomia - Cadastro de cartão',
		canActivate: [authGuard],
	},
	{
		path: 'reservas-e-pagamento',
		loadComponent: pages.OrdersHistoryPageComponent,
		title: 'Unico Gastronomia - Histórico de reservas/checkins',
		canActivate: [authGuard],
	},
	{
		path: 'reservas-e-pagamento/reserva/:orderId',
		loadComponent: pages.OrderDetailsPageComponent,
		canActivate: [authGuard],
	},
	{
		path: 'reservas-e-pagamento/pagamento/:orderId',
		loadComponent: pages.PaymentPageComponent,
		title: 'Unico Gastronomia - Pagamento',
		canActivate: [authGuard],
	},
	{
		path: 'reservas-e-pagamento',
		loadComponent: pages.OrdersHistoryPageComponent,
		title: 'Unico Gastronomia - Histórico de reservas/checkins',
		canActivate: [authGuard],
	},
	{
		path: 'reservas-e-pagamento/pagamento/:orderId/sucesso',
		loadComponent: pages.PaymentSuccessComponent,
		title: 'Unico Gastronomia - Pagamento Sucesso',
		canActivate: [authGuard],
	},
	{
		path: 'como-funciona',
		loadComponent: pages.HowItWorkComponent,
		title: 'Unico Gastronomia - Como funciona',
	},
	{
		path: 'termos-de-uso',
		loadComponent: pages.TermsOfUseComponent,
		title: 'Unico Gastronomia - Termos de uso e condições',
	},
	{
		path: 'contatos',
		loadComponent: pages.ContactsComponent,
		title: 'Unico Gastronomia - Contatos',
	},
	{
		path: 'faq',
		loadComponent: pages.FaqComponent,
		title: 'Unico Gastronomia - FAQ',
	},
	{
		path: 'reservas-e-pagamento',
		loadComponent: pages.OrdersHistoryPageComponent,
		title: 'Unico Gastronomia - Histórico de reservas/checkins',
		canActivate: [authGuard],
	},
	{
		path: 'no-signal',
		loadComponent: pages.NoSignal,
		title: 'Unico Gastronomia - Erro',
	},
	{ path: '**', redirectTo: '', pathMatch: 'full' },
]
