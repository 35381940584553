import { createFeatureSelector, createSelector } from '@ngrx/store'
import { ReservationState, featureReservationName } from './reservation.state'
import { Reservation } from '../../models/vo/reservation'

const getAvailabilityFeatureState = createFeatureSelector<ReservationState>(featureReservationName)

export const getStatusAvailabilities = createSelector(
	getAvailabilityFeatureState,
	state => state.statusAvailabilities
)

export const getStatusConfirmReservation = createSelector(
	getAvailabilityFeatureState,
	state => state.statusConfirmReservation
)

export const isLoadingAvailabililities = createSelector(
	getAvailabilityFeatureState,
	state => state.statusAvailabilities === 'pending'
)

export const isLoadingConfirmReservation = createSelector(
	getAvailabilityFeatureState,
	state => state.statusConfirmReservation === 'pending'
)
export const getAvailabilities = createSelector(getAvailabilityFeatureState, state => state.availabilities)
export const getSelectedDate = createSelector(getAvailabilityFeatureState, state => state.selectedDate)
export const getSections = createSelector(getAvailabilityFeatureState, state => state.sections)
export const getSelectedSection = createSelector(
	getAvailabilityFeatureState,
	state => state.selectedSectionLabel
)
export const getSchedules = createSelector(getAvailabilityFeatureState, state => state.schedules)
export const getSelectedPartySize = createSelector(
	getAvailabilityFeatureState,
	state => state.selectedPartySize
)
export const getReservationTimes = createSelector(
	getAvailabilityFeatureState,
	state => state.reservationTimes
)
export const getSelectedReservationTime = createSelector(
	getAvailabilityFeatureState,
	state => state.selectedReservationTime
)
export const getErrorAvailability = createSelector(
	getAvailabilityFeatureState,
	state => state.errorAvailability
)
export const getErrorConfirmation = createSelector(
	getAvailabilityFeatureState,
	state => state.errorConfirmation
)
export const getOrderId = createSelector(getAvailabilityFeatureState, state => state.orderId)

export const getConfirmReservation = createSelector(getAvailabilityFeatureState, state => {
	return new Reservation(
		state.selectedPartySize ? Number(state.selectedPartySize) : null,
		state.selectedSectionLabel,
		state.selectedDate,
		state.selectedReservationTime,
		state.selectedSectionId
	)
})
