import { VenueDetail } from '../../models/venues/venues-details'
import { VenueFilter } from '../../models/venues/venues-filter'
import { Status } from '../helper.state'

export const featureVenueName = 'venue'

export interface VenueState {
	venues: VenueFilter[] | null
	filterVenues: VenueFilter[] | null
	statusGetVenues: Status
	errorGetVenues: string | null
	allLoaded: boolean
	selectedVenue: VenueDetail | null
	statusSelectedVenue: Status
	erroGetVenue: string | null
	statusLoadingMoreVenues: Status
	errorLoadingMoreVenues: string | null
	location: { latitude: number; longitude: number; maxDistance: number } | null
}

export const initialStateVenue: VenueState = {
	venues: null,
	filterVenues: null,
	statusGetVenues: 'idle',
	errorGetVenues: null,
	selectedVenue: null,
	allLoaded: false,
	statusSelectedVenue: 'idle',
	erroGetVenue: null,
	statusLoadingMoreVenues: 'idle',
	errorLoadingMoreVenues: null,
	location: null,
}
