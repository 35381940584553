import { Status } from '../helper.state'
import { AvailabilityReservation, ReservationTime, Schedules, Section } from './reservation'

export const featureReservationName = 'reservation'

export interface ReservationState {
	availabilities: AvailabilityReservation[] | null
	selectedDate: string | null
	sections: Section[] | undefined | null
	selectedSectionLabel: string | null
	selectedSectionId: string | null
	schedules: Schedules[] | undefined | null
	selectedPartySize: string | null
	reservationTimes: ReservationTime[] | undefined | null
	selectedReservationTime: string | null
	errorAvailability: string | null
	errorConfirmation: string | null
	orderId: string | null
	statusAvailabilities: Status
	statusConfirmReservation: Status
}

export const CHOOSE_DATE = 'Escolha a data'
export const CHOOSE_SECTION = 'Selecione o ambiente'
export const CHOOSE_PARTYSIZE = 'Selecione a qtd de pessoas'

export const resetState = {
	selectedDate: null,
	sections: null,
	selectedSectionLabel: null,
	selectedSectionId: null,
	schedules: null,
	selectedPartySize: null,
	reservationTimes: null,
	selectedReservationTime: null,
	// status
	errorAvailability: null,
	//errorConfirmation: null,
}

export const reservationInitialState: ReservationState = {
	availabilities: null,
	orderId: null,
	...resetState,
	statusAvailabilities: 'idle',
	statusConfirmReservation: 'idle',
	errorConfirmation: null,
}
