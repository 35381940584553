import { Component, HostListener, inject } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { Router, RouterModule } from '@angular/router'
import { AuthDirective } from '@monorepo-channels/channels/domain'
import { AuthService } from '@monorepo-channels/channels/domain'
import { filter, interval, switchMap } from 'rxjs'
import { ClientGateway } from '@monorepo-channels/channels/domain'

@Component({
	standalone: true,
	imports: [RouterModule],
	selector: 'unicred-root',
	template: ` <router-outlet></router-outlet> `,
	hostDirectives: [AuthDirective],
})
export class AppComponent {
	private router = inject(Router)
	private authService = inject(AuthService)
	private clientGateway = inject(ClientGateway)

	constructor() {
		interval(30000)
			.pipe(
				takeUntilDestroyed(),
				filter(() => Boolean(this.authService.getToken())),
				switchMap(() => this.clientGateway.isLoggedIn())
			)
			.subscribe()
	}

	@HostListener('window:offline')
	setNetworkOffline(): void {
		this.router.navigate(['/gastronomia/no-signal'])
	}
}

// deploy test
