import { ApplicationConfig, LOCALE_ID } from '@angular/core'
import { provideRouter, withEnabledBlockingInitialNavigation } from '@angular/router'
import { APP_ROUTES } from './app.routes'
import { provideHttpClient, withInterceptors } from '@angular/common/http'
import { PartnersId, PartnerToken, ProviderCurrency } from '@monorepo-channels/shared/util-helpers'
import {
	LoginPageRegex,
	ProvidersRedux,
	RedirectToken,
	clientPartnerInterceptor,
	tokenInterceptor,
} from '@monorepo-channels/channels/domain'

import ptBr from '@angular/common/locales/pt'
import { registerLocaleData } from '@angular/common'
import { provideEnvironmentNgxMask } from 'ngx-mask'
registerLocaleData(ptBr)

export const appConfig: ApplicationConfig = {
	providers: [
		{ provide: PartnerToken, useValue: PartnersId.UNICRED },
		{ provide: RedirectToken, useValue: 'login' },
		{ provide: LoginPageRegex, useValue: /\/login\?login=([^&]+)/ },
		{ provide: LOCALE_ID, useValue: 'pt-BR' },
		provideRouter(APP_ROUTES, withEnabledBlockingInitialNavigation()),
		provideHttpClient(withInterceptors([clientPartnerInterceptor, tokenInterceptor])),
		ProvidersRedux(),
		ProviderCurrency(),
		provideEnvironmentNgxMask(),
	],
}
