import { Injectable } from '@angular/core'

@Injectable({ providedIn: 'root' })
export class PKCEService {
	private crypto: Crypto

	constructor() {
		this.crypto = globalThis.crypto
	}

	/**
	 * Creates an array of length `size` of random bytes
	 * @param size
	 * @returns Array of random ints (0 to 255)
	 */
	private getRandomValues(size: number): Uint8Array {
		return this.crypto.getRandomValues(new Uint8Array(size))
	}

	/** Generate cryptographically strong random string
	 * @param size The desired length of the string
	 * @returns The random string
	 */
	private random(size: number): string {
		const mask = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-._~'
		let result = ''
		const randomUints = this.getRandomValues(size)
		for (let i = 0; i < size; i++) {
			const randomIndex = randomUints[i] % mask.length
			result += mask[randomIndex]
		}
		return result
	}

	/** Generate a PKCE challenge verifier
	 * @param length Length of the verifier
	 * @returns A random verifier `length` characters long
	 */
	private generateVerifier(length: number): string {
		return this.random(length)
	}

	/** Generate a PKCE code challenge from a code verifier
	 * @param code_verifier
	 * @returns The base64 url encoded code challenge
	 */
	private async generateChallenge(code_verifier: string): Promise<string> {
		const buffer = await this.crypto.subtle.digest('SHA-256', new TextEncoder().encode(code_verifier))
		return btoa(String.fromCharCode(...new Uint8Array(buffer)))
			.replace(/\//g, '_')
			.replace(/\+/g, '-')
			.replace(/=/g, '')
	}

	/** Generate a PKCE challenge pair
	 * @param length Length of the verifier (between 43-128). Defaults to 43.
	 * @returns PKCE challenge pair
	 */
	async pkceChallenge(length: number = 43): Promise<{ codeVerifier: string; codeChallenge: string }> {
		if (length < 43 || length > 128) {
			throw new Error(`Expected a length between 43 and 128. Received ${length}.`)
		}
		const verifier = this.generateVerifier(length)
		const challenge = await this.generateChallenge(verifier)
		return {
			codeVerifier: verifier,
			codeChallenge: challenge,
		}
	}
}
