import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { environment } from '@monorepo-channels/shared/util-environments'
import { PKCEService } from '@monorepo-channels/shared/util-helpers'
import { v4 as uuidv4 } from 'uuid'

@Injectable({
	providedIn: 'root',
})
export class AuthLiveloService {
	keycloakHost = environment.livelo.auth
	clientId = environment.livelo.clientId
	clientSecret = environment.livelo.clientSecret
	redirectUri = environment.livelo.redirectUri
	codeVerificarKey = 'code_verifier'

	constructor(
		private http: HttpClient,
		private pkceService: PKCEService
	) {}

	async getAuthorizationCode() {
		const { codeVerifier, codeChallenge } = await this.pkceService.pkceChallenge()
		localStorage.setItem(this.codeVerificarKey, codeVerifier)
		const params = new HttpParams()
			.set('client_id', environment.livelo.clientId)
			.set('scope', 'openid')
			.set('response_type', 'code')
			.set('response_mode', 'fragment')
			.set('code_challenge_method', 'S256')
			.set('code_challenge', codeChallenge)
			.set('state', uuidv4())
			.set('nonce', uuidv4())
			// .set('redirect_uri', environment.livelo.redirectUri)
			.set('redirect_uri', 'https://oidcdebugger.com/debug')

		const authUrl = `${
			this.keycloakHost
		}/auth/realms/LIV_PF/protocol/openid-connect/auth?${params.toString()}`
		window.location.href = authUrl
	}

	exchangeCodeForToken(code: string) {
		const codeVerifier = localStorage.getItem(this.codeVerificarKey)
		const tokenUrl = `${this.keycloakHost}/auth/realms/LIV_PF/protocol/openid-connect/token`

		const body = new HttpParams()
			.set('client_id', this.clientId)
			.set('client_secret', this.clientSecret)
			.set('grant_type', 'authorization_code')
			.set('code', code)
			.set('code_verifier', codeVerifier || '')
			.set('redirect_uri', this.redirectUri)

		return this.http.post<{ access_token: string }>(tokenUrl, body.toString(), {
			headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }),
		})
	}
}
