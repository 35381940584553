import { Status } from '../helper.state'
import { Address } from './address'

export const featureAddressName = 'address'

export interface AddressState {
	addresses: Address[]
	selectedAddress: Address | null
	status: Status
	error: string
}

export const DEFAULT_ADDRESS_TEXT = 'Todas as cidades'

export const initialStateAddress: AddressState = {
	addresses: [],
	selectedAddress: null,
	error: '',
	status: 'idle',
}
