import { createReducer, on } from '@ngrx/store'
import { AddressActions } from './address.actions'
import { AddressState, initialStateAddress } from './address.state'
import { VenueActions } from '../venue/venue.actions'

export const addressReducer = createReducer<AddressState>(
	initialStateAddress,
	on(AddressActions.setCurrentAddress, (state, action): AddressState => {
		return {
			...state,
			selectedAddress: action.address,
		}
	}),
	on(AddressActions.loadAddresses, (state): AddressState => {
		return {
			...state,
			status: 'pending',
			error: '',
		}
	}),
	on(AddressActions.loadAddressesSuccess, (state, action): AddressState => {
		return {
			...state,
			addresses: action.addresses,
			status: 'success',
			error: '',
		}
	}),
	on(AddressActions.loadAddressesFailure, (state, action): AddressState => {
		return {
			...state,
			addresses: [],
			status: 'failure',
			error: action.error,
		}
	}),
	on(VenueActions.cleanFilter, (state): AddressState => {
		return {
			...state,
			selectedAddress: null,
		}
	}),
	on(AddressActions.cleanAddress, (state): AddressState => {
		return {
			...state,
			selectedAddress: null,
		}
	})
)
